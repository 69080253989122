import { domainName } from "../../shared/constants/constants";
import store from "../CommonStore";
import baseUrl, { baseUrlLogin } from "./interceptor";

let subDomainName;

// eslint-disable-next-line no-unused-vars
let storeSubscription = store.subscribe(() => {
  let name = store.getState().subDomain.subDomain;
  if (name !== "" && name !== domainName) {
    subDomainName = name;
  }
});

export const loginUser = (body) => {
  let headers = {
    subdomain: subDomainName ? subDomainName : "",
  };

  return baseUrlLogin.post("auth/user/login", body, {
    headers,
  });
};

export const resetPassword = (body, token, isNewUser) => {
  let header = {
    subdomain: subDomainName ? subDomainName : "",
  };

  return baseUrlLogin.post("auth/user/resetPassword", body, {
    params: {
      token,
      isNewUser: isNewUser ? true : false
    },
    headers: header,
  });
};

export const logoutUser = () => {
  return baseUrl.post("auth/user/logout");
};

export const verifyResetPasswordToken = (token) => {
  let headers = {
    subdomain: subDomainName ? subDomainName : "",
  };

  return baseUrlLogin.get("auth/verify/token", {
    params: {
      token,
    },
    headers: headers,
  });
};

export const forgotPassword = (body) => {
  let header = {
    subdomain: subDomainName ? subDomainName : "",
  };

  return baseUrlLogin.post("auth/user/forgotPassword", body, {
    headers: header,
  });
};
