import axios from 'axios';
import {
  apiDevEndpoint,
  apiProdEndpoint,
} from '../../shared/constants/constants';
import { Auth } from '../helpers';

//Base URL end point

const urlDev = apiDevEndpoint;
const urlProd = apiProdEndpoint;

export const baseUrlLogin = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? urlDev : urlProd,
});

export const baseUrl = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? urlDev : urlProd,
  headers: {
    'Content-Type': 'application/json',
  },
});

//Request
baseUrl.interceptors.request.use(
  function (config) {
    const authorizationToken = Auth.getAuthToken();
    const subDomain = Auth.getSubDomainName();
    if (authorizationToken) {
      config.headers['Authorization'] = authorizationToken;
    }
    if (subDomain) {
      config.headers['subdomain'] = subDomain;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  },
);

// Response
baseUrl.interceptors.response.use(
  function (response) {
    // console.log(response);
    if (response.data.responseObject) {
      return response.data.responseObject;
    } else {
      return response.data;
    }
  },
  function (error) {
    if (error.response.status === 401) {
      sessionStorage.clear();
      window.location.reload();
    }
    return error;
  },
);

export default baseUrl;
