import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import {
  createMask,
  destroyMask,
  emailRegex,
  onlyText,
  privileges,
  roles,
  Status,
  titles,
  userRole,
} from "../../shared/constants/constants";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import "./AddUser.css";
import AppInput from "../../Components/AppInput/AppInput";
import AppSelect from "../../Components/AppSelect/AppSelect";
import { useHistory, useLocation } from "react-router";
import { ToastrService } from "../../Utils/services/tostr.service";
import AppLoader from "../../Components/AppLoader/AppLoader";
import AppAutocomplete from "../../Components/AppAutocomplete/AppAutocomplete";
import {
  addNewUser,
  editUser,
  getUserDetails,
} from "../../Utils/services/user.service";
import { Auth } from "../../Utils/helpers";

export default function AddUser(props) {
  useDocumentTitle("Add User");

  const initialState = {
    fields: {},
    error: {},
  };

  const [formValues, setFormValues] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [viewForm, setViewForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [userRoleList, setUserRoleList] = useState(userRole);

  const history = useHistory();

  const { search } = useLocation();

  useEffect(() => {
    setFormValues(initialState);
    const query = new URLSearchParams(search);
    const viewFlag = query.get("view");
    const editFlag = query.get("edit");
    const userEmail = query.get("email");
    setViewForm(false);
    setEditForm(false);

    const newUserRoleList = userRole?.map((item) => ({
      id: item?.value,
      initials: item?.name,
    }));
    setUserRoleList(newUserRoleList);

    if (viewFlag) setViewForm(true);
    if (editFlag) setEditForm(true);
    if (userEmail) getUserInfo(userEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getUserInfo = async (userEmail) => {
    setIsLoading(true);
    try {
      let result = await getUserDetails(userEmail);
      if (result.code === 200) {
        if (result.body) {
          let data = {
            ...result.body,
            phoneNumber: createMask(result.body.phoneNumber.toString()),
            isActive: result.body.isActive ? "Active" : "InActive",
            userRole: result.body.roles.includes(roles.admin)
              ? roles.admin
              : roles.non_admin,
            isBatchSearchAllowed: result.body.privileges.includes(
              privileges.batch_search
            )
              ? true
              : false,
            isViewAllSearchAllowed: result.body.privileges.includes(
              privileges.view_all_searches
            )
              ? true
              : false,
          };
          setFormValues({ fields: data, error: {} });
        }
      } else {
        ToastrService.error(result.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const handleChange = (e, field) => {
    setFormError("");
    let fields = formValues.fields;

    let value =
      e.target.name === "checkbox" ? e.target.checked : e.target.value;

    if (field === "phoneNumber") {
      let temp = destroyMask(e.target.value);
      value = createMask(temp);
    }

    let userRoleArr = [];
    fields[field] = value;

    let err = formValues.error;
    err[field] = "";

    if (field === "userRole") {
      // if (
      //   fields?.userRole?.includes(roles.download_only) ||
      //   fields?.userRole?.length === 1
      // ) {
      //   fields.isBatchSearchAllowed =
      //     value?.includes(roles.download_only) && false;

      //   fields.isViewAllSearchAllowed =
      //     value?.includes(roles.download_only) && false;
      // }

      fields.isBatchSearchAllowed =
        value === roles.admin && Auth.isBatchSearchAllowedFlag()
          ? true
          : false;
    }

    setFormValues({ ...formValues, fields, err });
  };

  const handleValidation = () => {
    let fields = formValues.fields;
    let errors = {};
    let formIsValid = true;

    if (editForm) {
      if (!fields["isActive"]) {
        formIsValid = false;
        errors["isActive"] = "Status is required.";
      }
    }

    if (fields["title"]) {
      if (fields["title"].length > 50) {
        formIsValid = false;
        errors["title"] = "Title must be maximum 50 characters.";
      }
    }

    if (!fields["firstName"] || fields["firstName"].trim() === "") {
      formIsValid = false;
      errors["firstName"] = "First Name is required.";
    } else if (fields["firstName"].length > 25) {
      formIsValid = false;
      errors["firstName"] = "First Name must be maximum 25 characters.";
    } else if (!onlyText.test(fields["firstName"])) {
      formIsValid = false;
      errors["firstName"] = "Invalid First Name. Only text allowed.";
    }

    if (!fields["lastName"] || fields["lastName"].trim() === "") {
      formIsValid = false;
      errors["lastName"] = "Last Name is required.";
    } else if (fields["lastName"].length > 25) {
      formIsValid = false;
      errors["lastName"] = "Last Name must be maximum 25 characters.";
    } else if (!onlyText.test(fields["lastName"])) {
      formIsValid = false;
      errors["lastName"] = "Invalid Last Name. Only text allowed.";
    }

    if (!fields["phoneNumber"]) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone Number is required.";
    } else if (destroyMask(fields["phoneNumber"]).length < 10) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone Number must be minimum 10 characters.";
    } else if (destroyMask(fields["phoneNumber"]).length > 10) {
      formIsValid = false;
      errors["phoneNumber"] = "Phone Number must be maximum 10 characters.";
    }

    if (!fields["userRole"] || !fields["userRole"]?.length) {
      formIsValid = false;
      errors["userRole"] = "User role is required.";
    }

    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "Email ID is required.";
    } else if (fields["email"].length > 50) {
      formIsValid = false;
      errors["email"] = "Email ID must be maximum 50 characters.";
    } else {
      if (!emailRegex.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = "Invalid Email ID.";
      }
    }

    if (!editForm) {
      if (!fields["confirmEmail"]) {
        formIsValid = false;
        errors["confirmEmail"] = "Confirm Email ID is required.";
      } else if (fields["confirmEmail"].length > 50) {
        formIsValid = false;
        errors["confirmEmail"] =
          "Confirm Email ID must be maximum 50 characters.";
      } else {
        if (!emailRegex.test(fields["confirmEmail"])) {
          formIsValid = false;
          errors["confirmEmail"] = "Invalid Confirm Email ID.";
        }
      }

      if (fields["email"] !== fields["confirmEmail"]) {
        formIsValid = false;
        errors["confirmEmail"] = "Email ID's don’t match.";
      }
    }

    setFormValues({ ...formValues, error: errors });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const {
      id,
      title,
      firstName,
      lastName,
      phoneNumber,
      email,
      isViewAllSearchAllowed,
      isActive,
      userRole,
      isBatchSearchAllowed,
    } = formValues.fields;

    if (handleValidation()) {
      try {
        let response;
        let userRoleArray = [];
        userRole?.includes(roles.admin) ? userRoleArray?.push(userRole) : userRoleArray?.push(userRole);
        // userRoleArray.push(
        //   ...userRole?.map((elements) => elements?.replace(" ", "_"))
        // );
        if (Auth.isBatchSearchAllowedFlag() && isBatchSearchAllowed) {
          userRoleArray.push(roles.batch_admin);
        } else {
          userRoleArray.filter((item) => item?.roles !== roles.batch_admin);
        }
        if (isViewAllSearchAllowed) {
          userRoleArray.push(roles.search_admin);
        } else {
          userRoleArray.filter((item) => item?.roles !== roles.search_admin);
        }
        if (!editForm) {
          response = await addNewUser({
            title: title ? title.trim() : "",
            firstName,
            lastName,
            phoneNumber: destroyMask(phoneNumber),
            email,
            isViewAllSearchAllowed,
            roles: userRoleArray,
            isBatchSearchAllowed
          });
        } else {
          response = await editUser({
            id,
            title: title ? title.trim() : "",
            firstName,
            lastName,
            email,
            phoneNumber: destroyMask(phoneNumber),
            isViewAllSearchAllowed,
            isActive: isActive === "Active" ? true : false,
            roles: userRoleArray,
            isBatchSearchAllowed
          });
        }

        if (response.code === 200) {
          ToastrService.success(response.message);
          props.history.push("/account/userList");
        } else {
          setFormError(response.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setFormError(error.message);
      }
    } else {
      setIsLoading(false);
      setFormError(
        "Please provide information for required fields highlighted in red."
      );
    }
  };

  function clearForm() {
    setFormValues(initialState);
    history.push("/account/userList");
  }

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <div className="container-fluid">
        <div className="pageTitle">
          {viewForm
            ? "User Details"
            : editForm
              ? "Edit User Details"
              : "New User Registration"}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="addUserSection">
            <div className="mt-4">
              <Row>
                <Col md={{ size: 6 }} className="addUserInputs">
                  {(viewForm || editForm) && (
                    <AppInput
                      label="Account Number"
                      className="mb-4"
                      type="text"
                      value={formValues.fields.accountNumber}
                      disabled={viewForm || editForm}
                    />
                  )}
                  <AppAutocomplete
                    label="Title"
                    className="mb-4 addUserSelectInput"
                    value={formValues.fields.title}
                    options={titles}
                    errorMsg={formValues.error.title}
                    validations={{
                      maxLength: 50,
                    }}
                    onChange={(e) => handleChange(e, "title")}
                    disabled={viewForm ? true : editForm ? false : false}
                  />
                  <AppInput
                    label="First Name"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.firstName}
                    errorMsg={formValues.error.firstName}
                    validations={{
                      required: true,
                      maxLength: 25,
                      pattern: onlyText,
                    }}
                    onChange={(e) => handleChange(e, "firstName")}
                    disabled={viewForm ? true : editForm ? false : false}
                  />
                  <AppInput
                    label="Last Name"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.lastName}
                    errorMsg={formValues.error.lastName}
                    validations={{
                      required: true,
                      maxLength: 25,
                      pattern: onlyText,
                    }}
                    onChange={(e) => handleChange(e, "lastName")}
                    disabled={viewForm ? true : editForm ? false : false}
                  />
                  {formValues.fields.userRole &&
                    (editForm || viewForm) ?
                    (<AppSelect
                      label="User Role"
                      className="mb-4 addAccountSelectInput"
                      value={formValues.fields.userRole}
                      options={userRoleList}
                      errorMsg={formValues.error.userRole}
                      onChange={(e) => handleChange(e, "userRole")}
                      disabled={viewForm ? true : editForm ? false : false}
                      editDetails={editForm || viewForm}
                      required
                    />)
                    :
                    (<AppSelect
                      label="User Role"
                      className="mb-4 addAccountSelectInput"
                      value={formValues.fields.userRole}
                      options={userRoleList}
                      errorMsg={formValues.error.userRole}
                      onChange={(e) => handleChange(e, "userRole")}
                      disabled={viewForm ? true : editForm ? false : false}
                      addForm={viewForm}
                      required
                    />)
                  }
                  {/* {(!viewForm && !editForm) && (
                    <AppMultipleSelect
                      label="Allow Search in States"
                      className="mb-4 addUserSelectInput"
                      value={formValues.fields.allowedStateIdList}
                      options={stateList}
                      errorMsg={formValues.error.allowedStateIdList}
                      onChange={(e) => handleChange(e, "allowedStateIdList")}
                      required
                    />
                  )} */}
                </Col>
                <Col md={{ size: 6 }} className="addUserInputs">
                  {formValues.fields.isActive && (editForm || viewForm) ? (
                    <AppSelect
                      label="Status"
                      className="mb-4 addAccountSelectInput"
                      value={formValues.fields.isActive}
                      options={Status}
                      errorMsg={formValues.error.isActive}
                      onChange={(e) => handleChange(e, "isActive")}
                      disabled={viewForm ? true : editForm ? false : true}
                      setNameForView={true}
                      editDetails={editForm || viewForm}
                      required
                    />
                  )
                    :
                    ""}
                  <AppInput
                    label="Phone Number"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.phoneNumber}
                    errorMsg={formValues.error.phoneNumber}
                    validations={{
                      required: true,
                    }}
                    onChange={(e) => handleChange(e, "phoneNumber")}
                    disabled={viewForm ? true : editForm ? false : false}
                  />

                  <AppInput
                    label="Email ID"
                    className="mb-4"
                    type="text"
                    value={formValues.fields.email}
                    errorMsg={formValues.error.email}
                    validations={{
                      required: true,
                      maxLength: 50,
                      pattern: emailRegex,
                    }}
                    onChange={(e) => handleChange(e, "email")}
                    disabled={viewForm ? true : editForm ? false : false}
                  />

                  {!viewForm && !editForm ? (
                    <AppInput
                      label="Confirm Email ID"
                      className="mb-4"
                      type="text"
                      value={formValues.fields.confirmEmail}
                      errorMsg={formValues.error.confirmEmail}
                      validations={{
                        required: true,
                        maxLength: 50,
                        pattern: emailRegex,
                      }}
                      onChange={(e) => handleChange(e, "confirmEmail")}
                      disabled={viewForm ? true : editForm ? true : false}
                    />
                  ) :
                    ""
                  }
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 6 }} className="addUserInputs">
                  <Row>
                    <Col md={{ size: 6 }} className="addAccountSmallInputs">
                      <FormGroup check inline className="mb-2">
                        <Input
                          id="viewAllSearchRecordCheckbox"
                          type="checkbox"
                          name="checkbox"
                          checked={
                            formValues.fields.isViewAllSearchAllowed
                              ? formValues.fields.isViewAllSearchAllowed
                              : false
                          }
                          onChange={(e) =>
                            handleChange(e, "isViewAllSearchAllowed")
                          }
                          disabled={viewForm ? true : editForm ? false : false}
                        />
                        <Label check>View All Search Records</Label>
                      </FormGroup>
                    </Col>
                    <Col md={{ size: 6 }} className="addAccountSmallInputs">
                      <FormGroup check inline className="mb-2">
                        <Input
                          type="checkbox"
                          name="checkbox"
                          checked={
                            formValues.fields.isBatchSearchAllowed
                              ? formValues.fields.isBatchSearchAllowed
                              : false
                          }
                          onChange={(e) =>
                            handleChange(e, "isBatchSearchAllowed")
                          }
                          disabled={
                            viewForm ? true :
                              formValues.fields.userRole === roles.admin
                                ? true
                                : formValues.fields.userRole === roles.non_admin
                                  ? false
                                  : false
                          }
                        />
                        <Label check>Allow Batch Search</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {(!viewForm || editForm) && (
                <div>
                  <Row className="mb-3">
                    <Col>
                      {formError !== "" && (
                        <span className="text-danger errorMsg">
                          {formError}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={{ size: 6 }} className="addUserBtns">
                      <div>
                        <Button className="addUserBtn" type="submit">
                          {isLoading && (
                            <Spinner size="sm" className="mx-2"></Spinner>
                          )}
                          Submit
                        </Button>
                      </div>
                      <div>
                        <Button
                          className="addUserBtn cancelBtn"
                          type="button"
                          onClick={clearForm}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
