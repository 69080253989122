import { TableCell, TableRow } from "@mui/material";
import React from "react";
import "./TableLoader.css";

export default function TableLoader(props) {
  const { columnsNumber } = props;

  const tableCell = () => {
    let tableCells = [];
    for (let i = 1; i <= columnsNumber; i++) {
      tableCells.push(
        <TableCell key={i} className="loading">
          <div className="bar"></div>
        </TableCell>
      );
    }
    return tableCells;
  };

  return (
    <>
      <TableRow>{tableCell()}</TableRow>
    </>
  );
}
