import { Box, Modal, TableBody, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import useTable from "../../Components/useTable";
import "./AccountsList.css";
import InfiniteScroll from "react-infinite-scroll-component";
import TableLoader from "../../Components/TableLoader/TableLoader";
import {
  getListOfTenants,
  resendInviteToAccount,
  deleteAccount,
} from "../../Utils/services/accounts.service";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import { useHistory } from "react-router";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import IconAppInput from "../../Components/IconAppInput/IconAppInput";
import { ToastrService } from "../../Utils/services/tostr.service";
import searchIcon from "../../assets/icons/small_search.png";
import AppLoader from "../../Components/AppLoader/AppLoader";
import { useDispatch, useSelector } from "react-redux";
import * as ActionTypes from "../../Redux/ActionTypes";
import {
  convertTZ,
  roles,
  Status,
} from "../../shared/constants/constants";
import AppSelect from "../../Components/AppSelect/AppSelect";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 650 ? 600 : 350,
  bgcolor: "background.paper",
  border: "none",
  outline: "none",
  boxShadow: 24,
  p: 4,
};

const headCells = [
  { id: "tenant", label: "Company Name", width: "25%" },
  // { id: "apiKey", label: "API Key", width: "10%", disableSorting: true },
  { id: "accountURL", label: "Portal URL", width: "30%", disableSorting: true },
  {
    id: "contactPerson",
    label: "Contact Person",
    width: "15%",
    disableSorting: true,
  },
  { id: "status", label: "Status", width: "10%", disableSorting: true },
  { id: "action", label: "Action", width: "20%", disableSorting: true },
];

const deleteModalActionBtns = [
  { name: "Delete", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

const resendEmailModalActionBtns = [
  { name: "Resend Email", submit: true },
  { name: "Cancel", className: "cancelBtn", submit: false },
];

export default function AccountsList() {
  useDocumentTitle("Accounts");

  const { accountData } = useSelector((state) => state);

  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [error, setError] = useState("");
  const [loader, setloader] = useState(false);
  const [searchQuery, setSearchQuery] = useState(null);
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilterValue, setStatusFilterValue] = useState("Active");
  const [statusOptions, setStatusOptions] = useState(Status);

  const [deleteModal, setDeleteModal] = useState(false);
  const [resendEmailModal, setResendEmailModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState({});

  const { TblContainer, TblHead } = useTable(data, headCells);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    if (accountData.accountId) {
      dispatch({
        type: ActionTypes.CLEAR_ACCOUNT_DATA,
      });
    }
    setStatusOptions([{ name: "All", value: "All" }, ...statusOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchListOfTenants = async (resetData = false) => {
    setloader(true);
    try {
      let response = await getListOfTenants({
        sortDirection: order,
        sortBy: orderBy,
        pageNumber: page,
        size: 15,
        searchBy: searchQuery,
        filterBy: {
          accountStatus: statusFilterValue.toUpperCase(),
        },
      });

      if (response.code === 200) {
        const temp = Array.from(resetData || !page ? [] : data);
        if (response.body) {
          temp.push(...response.body.content);
          temp.forEach((item) => {
            const date = convertTZ(item.onBoardingDate);
            item.onBoardingDate = dayjs(date).format("MM-DD-YYYY");
          });
          setData(temp);
          setTotalRecords(response.body.totalElements);
        }
        setloader(false);
        setIsLoading(false);
      } else {
        setloader(false);
        setIsLoading(false);
        setError(response.message);
      }
    } catch (error) {
      setloader(false);
      setIsLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchListOfTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy, page, searchQuery, statusFilterValue]);

  const handleSortRequest = (orderBy, order) => {
    document.getElementsByClassName("accountsListTable")[0].scrollTo(0, 0);
    setOrder(order);
    setPage(0);
    setOrderBy(orderBy);
    setTimeout(() => {
      setData([]);
    }, 10);
  };

  const deleteAccountClicked = async () => {
    setIsLoading(true);
    try {
      let response = await deleteAccount(selectedUser.accountNumber);

      if (response.code === 200) {
        ToastrService.success(response.message);
        fetchListOfTenants(true);
      } else {
        ToastrService.error(response.message);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const resendEmailToAccount = async () => {
    setIsLoading(true);
    try {
      let response = await resendInviteToAccount(selectedUser.accountNumber);

      if (response.code === 200) {
        ToastrService.success(response.message);
      } else {
        ToastrService.error(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      ToastrService.error(error.message);
    }
  };

  const addNewData = () => {
    setPage(page + 1);
  };

  return (
    <>
      <AppLoader isLoading={isLoading} />
      <div className="container-fluid">
        <div className="accountListSection">
          <Row className="mb-2">
            <Col lg={{ size: 6 }}>
              <div>
                <div className="pageTitle">List of Accounts</div>
              </div>
            </Col>
            <Col lg={{ size: 6 }}>
              <div className="listSearchBar">
                <div className="searchInput">
                  <IconAppInput
                    label="Search..."
                    icon={searchIcon}
                    placeholder="Search by Company name"
                    onEnter={(value) => {
                      if (searchQuery !== value) {
                        setData([]);
                        setTotalRecords(0);
                        setPage(0);
                        setSearchQuery(value);
                      }
                    }}
                  />
                </div>
                <div
                  className="appFilterIcon"
                  title="Filter"
                  onClick={() => setFilterModal(!filterModal)}
                ></div>
                <div className="accountListIcons text-end">
                  <div
                    className="smallAddIcon"
                    onClick={() => {
                      history.push("/account/add");
                    }}
                  ></div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              {error !== "" && (
                <span className="text-danger errorMsg">{error}</span>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <InfiniteScroll
                className="accountsListTable"
                dataLength={data.length}
                next={() => addNewData()}
                height="100%"
                hasMore={data.length < totalRecords}
                endMessage={
                  !loader &&
                  !data.length && (
                    <p className="text-secondary mt-3 text-center">
                      No Result Found
                    </p>
                  )
                }
              >
                <TblContainer>
                  <TblHead
                    handleSort={(orderBy, order) =>
                      handleSortRequest(orderBy, order)
                    }
                  />
                  <TableBody>
                    {data.map((item) => (
                      <TableRow key={item.accountNumber}>
                        <TableCell
                          title={item.tenant}
                          className="companyNameColumn"
                          onClick={() => {
                            history.push(
                              `/account/add?view=true&id=${item.accountNumber}`
                            );
                          }}
                        >
                          {item.tenant}
                          {item.onBoardingDate && (
                            <span>{`Onboarded: ${item.onBoardingDate}`}</span>
                          )}
                        </TableCell>
                        {/* <TableCell onClick={() => copyToClipboard(item.apiKey)}>
                          <div
                            title={item.apiKey ? "Click to copy" : ""}
                            className={`copyIconContainer ${item.apiKey ? "" : "disableContainer"}`}
                          >
                            <div className="copyIcon"></div>
                            <p className="copyText">Copy</p>
                          </div>
                        </TableCell> */}
                        <TableCell
                          className="portalUrlColumn"
                          onClick={() => {
                            window.open(item.accountURL, "_blank");
                          }}
                        >
                          {item.accountURL}
                        </TableCell>
                        <TableCell className="contactPersonColumn">
                          {item.userDto
                            ? `${item.userDto.firstName} ${item.userDto.lastName}`
                            : "-"}
                          {item.userDto &&
                            item.userDto.roles &&
                            item.userDto.roles.length && (
                              <span>{`Role: ${item.userDto.roles.includes(roles.company_admin)
                                  ? roles.company_admin?.replace("_", " ")
                                  : roles.admin?.replace("_", " ")
                                }`}</span>
                            )}
                        </TableCell>
                        <TableCell className="statusColumn">
                          {item.isActive ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell>
                          <div className="accountListIconSection text-start d-flex align-items-center">
                            <div
                              title="Edit Account"
                              className="smallEditIcon"
                              onClick={() => {
                                history.push(
                                  `/account/add?edit=true&id=${item.accountNumber}`
                                );
                              }}
                            ></div>
                            {/* <div
                              title="Delete Account"
                              className="smallDeleteIcon"
                              onClick={() => {
                                setSelectedUser(item);
                                setDeleteModal(true);
                              }}
                            ></div> */}
                            <div
                              title="Resend Invite"
                              className="smallResendInviteIcon"
                              onClick={() => {
                                setSelectedUser(item);
                                setResendEmailModal(true);
                              }}
                            ></div>
                            <div
                              title="Bill Account"
                              className="smallBillAccountIcon"
                              onClick={() => {
                                sessionStorage.setItem(
                                  "tenantName",
                                  item.tenant
                                );
                                sessionStorage.setItem(
                                  "accountId",
                                  item.accountId
                                );
                                history.push(`/account/bill`);
                              }}
                            ></div>
                            {item.isBatchSearchAllowed && (
                              <div
                                title="View Search"
                                className="smallViewSearchIcon"
                                onClick={() => {
                                  dispatch({
                                    type: ActionTypes.SAVE_ACCOUNT_DATA,
                                    payload: {
                                      accountId: item.accountId,
                                      tenantName: item.tenant,
                                      subDomain: item.subdomain,
                                    },
                                  });
                                  history.push(`/account/stats`);
                                }}
                              ></div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    {loader && <TableLoader columnsNumber={5} />}
                  </TableBody>
                </TblContainer>
              </InfiniteScroll>
            </Col>
          </Row>
        </div>
      </div>
      {deleteModal && (
        <ConfirmModal
          title="Are you sure you want to delete the account?"
          actionBtns={deleteModalActionBtns}
          onClose={(deleteFlag) => {
            if (typeof deleteFlag == "boolean" && deleteFlag) {
              deleteAccountClicked();
            }
            setDeleteModal(false);
          }}
        >
          <div className="my-5 text-center">
            <Row>
              <Col md={{ size: 6 }}>
                <div className="labelName">Company Name</div>
                <div className="labelValue">{selectedUser?.tenant}</div>
              </Col>
              <Col md={{ size: 6 }}>
                <div className="labelName">WWI Account Number</div>
                <div className="labelValue">{selectedUser?.accountNumber}</div>
              </Col>
            </Row>
          </div>
        </ConfirmModal>
      )}
      {resendEmailModal && (
        <ConfirmModal
          title="Are you sure you want to resend email to the account?"
          actionBtns={resendEmailModalActionBtns}
          onClose={(resendEmailFlag) => {
            if (typeof resendEmailFlag == "boolean" && resendEmailFlag) {
              resendEmailToAccount();
            }
            setResendEmailModal(false);
          }}
        >
          <div className="my-5 text-center">
            <Row>
              <Col md={{ size: 6 }}>
                <div className="labelName">Company Name</div>
                <div className="labelValue">{selectedUser?.tenant}</div>
              </Col>
              <Col md={{ size: 6 }}>
                <div className="labelName">WWI Account Number</div>
                <div className="labelValue">{selectedUser?.accountNumber}</div>
              </Col>
            </Row>
          </div>
        </ConfirmModal>
      )}

      {filterModal && (
        <Modal open={true} disableEscapeKeyDown={true}>
          <Box sx={style}>
            {
              <div
                className="crossDeleteIconFilterModal"
                onClick={() => setFilterModal(false)}
              ></div>
            }
            <p className="mt-4 filterModalTitle">Filter</p>
            <div id="parent-modal-description" className="modalSection">
              <div className="statusFilterField">
                <AppSelect
                  label="Status"
                  className="mb-4"
                  value={statusFilterValue}
                  options={statusOptions}
                  onChange={(e) => {
                    setData([]);
                    setTotalRecords(0);
                    setPage(0);
                    setStatusFilterValue(e.target.value);
                    setFilterModal(false);
                  }}
                />
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
}
