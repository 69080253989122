import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import "./AppLoader.css";

export default function AppLoader(props) {
  const { isLoading } = props;

  return (
    <>
      {isLoading && (
        <div className="loaderBackdrop">
          <Stack
            sx={{ color: "grey.500" }}
            className="appLoaderContainer"
            spacing={2}
            direction="row"
          >
            <CircularProgress color="secondary" />
          </Stack>
        </div>
      )}
    </>
  );
}
