import React, { useState } from "react";
import "./AppInput.css";

import Proptypes from "prop-types";

export default function AppInput(props) {
  const [error, setError] = useState("");
  const [passErrorMsg, setPassErrorMsg] = useState();

  const {
    label,
    placeholder,
    type,
    onChange,
    className,
    value="",
    errorMsg,
    disabled,
    validations,
    helperText,
    multi=false,
  } = props;

  const handleValidation = () => {
    if (validations) {
      const customValidations = Object.keys(validations);
      for (let i = 0; i < customValidations.length; i++) {
        switch (customValidations[i]) {
          case "required": {
            let haveError = checkRequired();
            if (haveError) return;
            break;
          }
  
          case "minLength": {
            let haveError = checkMinLength();
            if (haveError) return;
            break;
          }
  
          case "maxLength": {
            let haveError = checkMaxLength();
            if (haveError) return;
            break;
          }
  
          case "pattern": {
            let haveError = checkPattern();
            if (haveError) return;
            break;
          }
  
          default:
            break;
        }
      }
    }
  };

  const checkRequired = () => {
    let haveError = false;
    if (value && value.trim() !== "") {
      setError("");
    } else {
      haveError = true;
      setError(`${label} is required.`);
    }
    return haveError;
  };

  const checkMinLength = () => {
    let haveError = false;
    if (value && value.length < validations["minLength"]) {
      haveError = true;
      setError(
        `${label} must be minimum ${validations["minLength"]} characters.`
      );
    } else {
      setError("");
    }
    return haveError;
  };

  const checkMaxLength = () => {
    let haveError = false;
    if (value && value.length > validations["maxLength"]) {
      haveError = true;
      setError(
        `${label} must be maximum ${validations["maxLength"]} characters.`
      );
    } else {
      setError("");
    }
    return haveError;
  };

  const checkPattern = () => {
    let haveError = false;
    if (value && !validations["pattern"].test(value)) {
      haveError = true;
      setError(`Invalid ${label}.`);
      if (type === 'password') {
        setPassErrorMsg("Hint: Password must have at least one uppercase and one special character.")
      }
    } else {
      setPassErrorMsg("");
      setError("");
    }
    return haveError;
  };

  return (
    <>
      <div
        className={`did-floating-label-content ${className ? className : ""}`}
      >
        <input
          className="did-floating-input"
          type={type}
          placeholder={placeholder ? placeholder : " "}
          value={value || ""}
          error={(error !== "" || (errorMsg && errorMsg !== "")) ? "error" : ""}
          onBlur={handleValidation}
          onKeyUp={handleValidation}
          onChange={onChange}
          disabled={disabled ? disabled : false}
          multiple={multi}
        />
        {
          (value || !placeholder)
          &&
          <label
            className="did-floating-label"
            style={{background: disabled ? '#F8F8F8' : 'white'}}
            error={(error !== "" || (errorMsg && errorMsg !== "")) ? "error" : ""}
          >
            {label}
          </label>
        }
        {(error !== "" || (errorMsg && errorMsg !== "")) && (
          // <span className="text-danger errorMsg">{error !== "" ? error : errorMsg ? errorMsg : ''}</span>
          <span className="text-danger errorMsg">{errorMsg && errorMsg !== "" ? errorMsg : error}</span>
        )}
        {(type === 'password' && (passErrorMsg && passErrorMsg !== "")) && (
          // <span className="text-danger errorMsg">{error !== "" ? error : errorMsg ? errorMsg : ''}</span>
          <span className="errorMsg d-block text-secondary">{passErrorMsg}</span>
        )}
        { ((error === "" && errorMsg === "") && (helperText && helperText !== "")) && (
          <span className="errorMsg text-secondary">{helperText}</span>
        )}
      </div>
    </>
  );
}

AppInput.prototype = {
  label: Proptypes.string,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  onChange: Proptypes.func,
  className: Proptypes.string,
};
