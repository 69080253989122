/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router";
import "./Login.css";
import useToken from "../../shared/hooks/useToken";
import {
  Container,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import useDocumentTitle from "../../shared/hooks/useDocumentTitle";
import AppInput from "../../Components/AppInput/AppInput";
import { loginUser } from "../../Utils/services/login.service";
import { Auth } from "../../Utils/helpers";
import { privileges, roles } from "../../shared/constants/constants";
import useSubDomainName from "../../shared/hooks/useSubDomainName";

export default function Login() {
  useDocumentTitle("Login");

  const initialState = {
    fields: {},
    error: {},
  };

  const [isLoading, setIsLoading] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [showError, setShowError] = useState(false);
  const [state, setState] = useState(initialState);

  const history = useHistory();

  const { subDomainName, setSubDomainName } = useSubDomainName();

  useEffect(() => {
    saveSubDomainName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSubDomainName = () => {
    if (subDomainName === "") {
      setSubDomainName();
    }
  };

  const { token, setToken } = useToken();

  if (token && accountType) {
    const userRole = Auth.getUserRole();

    if (userRole.includes(roles.super_admin)) {
      return <Redirect to="/account/list" />;
    } else if (
      accountType == privileges.subscription_only ||
      accountType == privileges.search_and_subscription
    ) {
      return <Redirect to="/account/downloads" />;
    } else {
      return <Redirect to="/account/search" />;
    }
  }

  function handleValidation() {
    let fields = state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["userName"]) {
      formIsValid = false;
      errors["userName"] = "User name is required";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "Password is required";
    }

    setState({ ...state, error: errors });
    setShowError(true);
    return formIsValid;
  }

  function handleChange(e, field) {
    let fields = state.fields;

    let value = e.target.value;
    fields[field] = value;

    let err = state.error;
    err[field] = "";

    err["alert"] = "";

    setState({ ...state, fields, err });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { userName, password } = state.fields;
    if (handleValidation()) {
      try {
        const response = await loginUser({
          email: userName,
          password,
        });

        const data = response.data.responseObject;
        if (data.code === 200) {
          setBrowserStorageItems(data.body);
          setToken(data.body.token);
          setAccountType(data?.body?.accountType);
        } else {
          const err = {
            alert: data.message,
          };
          setState({ ...state, error: err });
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        const err = {
          alert: error.message,
        };
        setState({ ...state, error: err });
      }
    } else {
      setIsLoading(false);
    }
  };

  const setBrowserStorageItems = (data) => {
    let privileges = data.privileges;
    privileges.push("MY_PROFILE");
    if (data.roles?.includes("SUPER_ADMIN")) {
      privileges.push("UPLOAD_FILES");
    } else if (
      data.roles?.includes("ADMIN") ||
      data.roles?.includes("NON_ADMIN") ||
      data.roles?.includes("COMPANY_ADMIN")
    ) {
      privileges.push("SUBSCRIPTION_ONLY");
      privileges.push("SEARCH_AND_SUBSCRIPTION");
    }

    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("displayName", data.displayName);
    sessionStorage.setItem("role", JSON.stringify(data.roles));
    sessionStorage.setItem("isBatchSearchAllowed", data.isBatchSearchAllowed);
    sessionStorage.setItem("isPrimaryAdmin", data.isPrimaryUser);
    sessionStorage.setItem("accountType", data.accountType);
    sessionStorage.setItem("privileges", JSON.stringify(privileges));

    if (data.accountNumber) {
      sessionStorage.setItem("accountNumber", data.accountNumber);
    }

    if (data.tenant) {
      sessionStorage.setItem("tenant", data.tenant);
    }
  };

  function goToHome() {
    history.push(`/`);
  }

  return (
    <Container fluid className="loginContainer">
      <div className="logoImg" onClick={goToHome}></div>
      <Row>
        <Col md={{ size: 5 }}>
          <div className="image-wrapper">
            <div></div>
          </div>
        </Col>
        <Col md={{ size: 7 }}>
          <div className="login-wrapper">
            <div>
              <div className="loginTitle">LOGIN</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="loginSection">
                <div className="inputControls">
                  {showError && state.error.alert && (
                    <Alert
                      fade={false}
                      color="danger"
                      toggle={() => setShowError(false)}
                    >
                      {state.error.alert}
                    </Alert>
                  )}

                  <AppInput
                    label="User Name"
                    errorMsg={showError ? state.error.userName : ""}
                    className="my-3"
                    type="text"
                    autoComplete="off"
                    value={state.fields.userName}
                    validations={{
                      required: true,
                    }}
                    onChange={(e) => handleChange(e, "userName")}
                  />
                  <AppInput
                    label="Password"
                    errorMsg={showError ? state.error.password : ""}
                    className="mb-3"
                    type="password"
                    value={state.fields.password}
                    validations={{
                      required: true,
                    }}
                    onChange={(e) => handleChange(e, "password")}
                  />

                  <Button
                    className="loginBtn"
                    type="button"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading && (
                      <Spinner size="sm" className="mx-2"></Spinner>
                    )}
                    Sign In
                  </Button>

                  <input type="submit" hidden />
                </div>
                <div className="footer">
                  <div className="forgot-password mb-3">
                    <a
                      className="link"
                      onClick={() => {
                        history.push(`forgotPassword`);
                      }}
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
