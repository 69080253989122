import React, { useState } from "react";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

export default function useTable(records, headCells, filterFn) {
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    <Table stickyHeader aria-label="sticky table">
      {props.children}
    </Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "desc";
      setOrder(isAsc ? "asc" : "desc");
      setOrderBy(cellId);
      props.handleSort(cellId, isAsc ? "ASC" : "DESC");
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className="customTableHeaderCell"
              sortDirection={orderBy === headCell.id ? order : false}
              width={headCell.width ? headCell.width : "auto"}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return {
    TblContainer,
    TblHead,
  };
}
